<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" @click="add" v-right-code="'SysStaff:Edit'">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="enable" v-right-code="'SysStaff:Enable'">启用</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="disable" v-right-code="'SysStaff:Disable'">停用</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="userListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='Code'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else-if="col.FieldName==='DisplayStatus'" width="100%"><el-tag :type="scope.row.DisplayStatus=='停用' ? 'danger' : 'success'">{{ scope.row[col.FieldName] }}</el-tag></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadStaffPageList", () => this.reloadPageList());
            this.Event.$on("onAdd", () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                isPassValidate: true,
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                tStaffOptions: {
                    visible: false
                },
                Id: '',
                Name:''
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysstaff/getpagelist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/sysstaff/getbyid", "get", { id: row.Id }, (data) => {
                    this.Event.$emit("clearEditUserForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            add() {
                this.$ajax.send("omsapi/sysstaff/getempty", "get", {}, (data) => {
                    data.StaffRelationList= data.StaffRelationList||[];
                    this.onChangeEditDataSource(data.Result);
                });
            },
            enable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择启用的员工.`, "error");
                    return false;
                }

                this.$ajax.send("omsapi/sysstaff/batchenable", "post", this.multipleSelection, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("员工启用成功.", "info");
                });
            },
            disable() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择停用员工.`, "error");
                    return false;
                }

                this.$ajax.send("omsapi/sysstaff/batchdisable", "post", this.multipleSelection, (data) => {
                    this.onPageChange(this.queryParam);
                    this.Utils.messageBox("员工停用成功.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            }
        },
        components: {
        }
    }
</script>
<style>

</style>